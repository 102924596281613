import axios from 'axios';

import {
    GET_ORDER_STATUS,
    GET_ORDER_STATUS_SUCCESS,
    GET_ORDER_STATUS_FAIL,
    GET_CUSTOMER_ARRIVED_STATUS,
    GET_CUSTOMER_ARRIVED_STATUS_SUCCESS,
    GET_CUSTOMER_ARRIVED_STATUS_FAIL,
    GET_CHECK_STORE_CODE,
    GET_CHECK_STORE_CODE_SUCCESS,
    GET_CHECK_STORE_CODE_FAIL,
    CLAIM_MANAGER_CODE,
    CLAIM_MANAGER_CODE_SUCCESS,
    CLAIM_MANAGER_CODE_FAIL,
    UPDATE_CUSTOMER_VEHICLE_DETAILS,
    UPDATE_CUSTOMER_VEHICLE_DETAILS_SUCCESS,
    UPDATE_CUSTOMER_VEHICLE_DETAILS_FAIL,
    CUSTOMER_CLICKED_DRIVEUP,
    CUSTOMER_CLICKED_DRIVEUP_SUCCESS,
    CUSTOMER_CLICKED_DRIVEUP_FAIL,
    CUSTOMER_CLICKED_WALKUP,
    CUSTOMER_CLICKED_WALKUP_SUCCESS,
    CUSTOMER_CLICKED_WALKUP_FAIL,
    ORDER_NOT_RECEIVED_COMPLETED,
    ORDER_NOT_RECEIVED_COMPLETED_FAIL,
    ORDER_NOT_RECEIVED_COMPLETED_SUCCESS,
    NEXT_STEP,
    PREV_STEP,
    GO_TO_STEP,
    UPDATE_TASK_LOG,
    UPDATE_MOCK_CARRY_OUT_INFO
} from './types';

import * as UtliService from "../services/utli-service";

import {
    showLoading,
    hideLoading
} from './LoadingActions';
import { ERROR_CODE } from 'constants/variable';

// import * as ROUTES from '../constants/routes';

export const updateTaskLog = (taskLogInfo) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_TASK_LOG,
            payload: taskLogInfo
        });
    }
}

export const updateCarryOutInfo = (carryOutInfo) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_MOCK_CARRY_OUT_INFO,
            payload: carryOutInfo
        });
    }
}



export const nextStep = () => {

    return async (dispatch) => {
        dispatch({
            type: NEXT_STEP
        });
    };
}

export const prevStep = () => {

    return async (dispatch) => {
        dispatch({
            type: PREV_STEP,
        });
    };
}

export const goToStep = (page) => {

    return async (dispatch) => {
        dispatch({
            type: GO_TO_STEP,
            payload: page
        });
    };
}

// ---------------------------------------------------------------- ---------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------- GET CHECK STORE CODE -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const getCheckStoreCode = (store_id, order_id, date, store_code) => {

    return async (dispatch, getState) => {
        dispatch(showLoading());
        dispatch({
            type: GET_CHECK_STORE_CODE
        });

        return axios({
            method: 'get',
            url: `/carryout/checkStoreCode`,
            params: {
                store_id,
                order_id,
                date,
                store_code
            },
        })
            .then(response => getCheckStoreCodeSuccess(dispatch, response, getState, store_id, order_id, date))
            .catch(error => getCheckStoreCodeFail(dispatch, error));
    };
}

const getCheckStoreCodeSuccess = (dispatch, response, getState, store_id, order_id, date) => {
    dispatch(hideLoading());
    if (UtliService.isSuccess(response)) {
        const { currentCarryoutInfo } = getState().main;
        if (currentCarryoutInfo.clientInfo && currentCarryoutInfo.clientInfo.customerEmail !== "") {
            // dispatch(onClaimCoupon(store_id, order_id, date));

            // dispatch({
            //     type: GO_TO_STEP,
            //     payload: 5
            // });
        } else {
            dispatch({
                type: NEXT_STEP
            });
        }
        dispatch({
            type: GET_CHECK_STORE_CODE_SUCCESS,
            payload: response.data,
        });

    } else {
        UtliService.alertError(response);
    }
};

const getCheckStoreCodeFail = (dispatch, error) => {
    dispatch(hideLoading());
    UtliService.alertError(error);

    dispatch({
        type: GET_CHECK_STORE_CODE_FAIL,
        payload: error
    });
    throw error;

};


// ---------------------------------------------------------------- ---------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------- GET ORDER -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const getOrderStatus = (store_id, order_id, date) => {

    return async (dispatch, getState) => {
        dispatch(showLoading());
        dispatch({
            type: GET_ORDER_STATUS
        });

        return axios({
            method: 'get',
            url: `/carryout/check`,
            params: {
                store_id,
                order_id,
                date
            },
        })
            .then(response => getOrderStatusSuccess(dispatch, response))
            .catch(error => getOrderStatusFail(dispatch, error));
    };
}

const getOrderStatusSuccess = (dispatch, response) => {
    dispatch(hideLoading());
    if (UtliService.isSuccess(response)) {
        dispatch({
            type: GET_ORDER_STATUS_SUCCESS,
            payload: response.data,
        });
    } else {
        UtliService.alertError(response);
    }
};

const getOrderStatusFail = (dispatch, error) => {
    dispatch(hideLoading());
    UtliService.alertError(error);
    dispatch({
        type: GET_ORDER_STATUS_FAIL,
        payload: error
    });
    throw error;

};


// ---------------------------------------------------------------- ---------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------- CUSTOMER ARRIVED  -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const triggerCustomerArrived = (store_id, order_id, date) => {

    return async (dispatch, getState) => {
        dispatch(showLoading());
        dispatch({
            type: GET_CUSTOMER_ARRIVED_STATUS
        });

        return axios({
            method: 'PATCH',
            url: `/carryout/arrive`,
            params: {
                store_id,
                order_id,
                date
            },
        })
            .then(response => triggerCustomerArrivedSuccess(dispatch, response))
            .catch(error => triggerCustomerArrivedFail(dispatch, error));
    };
}

const triggerCustomerArrivedSuccess = (dispatch, response) => {
    dispatch(hideLoading());
    console.log(response)
    if (UtliService.isSuccess(response)) {
        dispatch({
            type: GET_CUSTOMER_ARRIVED_STATUS_SUCCESS,
            payload: response.data,
        });
    } else if(response.data.errorCode === ERROR_CODE.ERROR_ARRIVE_NOT_ALLOWED){
        dispatch({
            type: GO_TO_STEP,
            payload: 6
        });
        throw response;
    }
};

const triggerCustomerArrivedFail = (dispatch, error) => {
    dispatch(hideLoading());
    UtliService.alertError(error);

    dispatch({
        type: GET_CUSTOMER_ARRIVED_STATUS_FAIL,
        payload: error
    });
    throw error;
};


// ---------------------------------------------------------------- ---------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------- CLAIM COUPON  -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const onClaimCoupon = (store_id, order_id, date, email) => {

    return async (dispatch, getState) => {
        dispatch(showLoading());
        dispatch({
            type: CLAIM_MANAGER_CODE
        });

        return axios({
            method: 'PATCH',
            url: `/carryout/claimCoupon`,
            params: {
                store_id,
                order_id,
                date,
                email
            },
        })
            .then(response => onClaimCouponSuccess(dispatch, response))
            .catch(error => onClaimCouponFail(dispatch, error));
    };
}

const onClaimCouponSuccess = (dispatch, response) => {
    dispatch(hideLoading());
    if (UtliService.isSuccess(response)) {
        dispatch({
            type: CLAIM_MANAGER_CODE_SUCCESS,
            payload: response.data,
        });
    }
};

const onClaimCouponFail = (dispatch, error) => {
    dispatch(hideLoading());
    UtliService.alertError(error);
    dispatch({
        type: CLAIM_MANAGER_CODE_FAIL,
        payload: error
    });
    throw error;

};

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------- CUSTOMER CLICKED DRIVE UP -------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const triggerCustomerDriveup = (store_id, order_id, date) => {

    return async (dispatch, getState) => {
        dispatch(showLoading());
        dispatch({
            type: CUSTOMER_CLICKED_DRIVEUP
        });

        return axios({
            method: 'PATCH',
            url: `/carryout/driveUpMethod`,
            params: {
                store_id,
                order_id,
                date
            },
        })
            .then(response => triggerCustomerDriveupSuccess(dispatch, response))
            .catch(error => triggerCustomerDriveupFail(dispatch, error));
    };
}

const triggerCustomerDriveupSuccess = (dispatch, response) => {
    dispatch(hideLoading());
    if (UtliService.isSuccess(response)) {
        dispatch({
            type: CUSTOMER_CLICKED_DRIVEUP_SUCCESS,
            payload: response.data,
        });
    } else {
        UtliService.alertError(response);
    }
};

const triggerCustomerDriveupFail = (dispatch, error) => {
    dispatch(hideLoading());
    UtliService.alertError(error);

    dispatch({
        type: CUSTOMER_CLICKED_DRIVEUP_FAIL,
        payload: error
    });
    throw error;

};

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------- CUSTOMER CLICKED WALK UP -------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const triggerCustomerWalkup = (store_id, order_id, date) => {

    return async (dispatch, getState) => {
        dispatch(showLoading());
        dispatch({
            type: CUSTOMER_CLICKED_WALKUP
        });

        return axios({
            method: 'PATCH',
            url: `/carryout/carryOutMethod`,
            params: {
                store_id,
                order_id,
                date
            },
        })
            .then(response => triggerCustomerWalkupSuccess(dispatch, response))
            .catch(error => triggerCustomerWalkupFail(dispatch, error));
    };
}

const triggerCustomerWalkupSuccess = (dispatch, response) => {
    dispatch(hideLoading());
    if (UtliService.isSuccess(response)) {
        dispatch({
            type: CUSTOMER_CLICKED_WALKUP_SUCCESS,
            payload: response.data,
        });
    } else {
        UtliService.alertError(response);
    }
};

const triggerCustomerWalkupFail = (dispatch, error) => {
    dispatch(hideLoading());
    UtliService.alertError(error);

    dispatch({
        type: CUSTOMER_CLICKED_WALKUP_FAIL,
        payload: error
    });
    throw error;

};

// ------------------------------------------------------------------------------------------------------------------------------------
// ------------------------- CUSTOMER UPDATE VEHICLE DETAILS --------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------------------------------------

export const updateCustomerVehicleDetails = (driveUpInfo, store_id, order_id, date) => {

    return async (dispatch, getState) => {
        dispatch(showLoading());
        dispatch({
            type: UPDATE_CUSTOMER_VEHICLE_DETAILS
        });

        return axios({
            method: 'POST',
            url: `/carryout/driveUpDetail`,
            params: {
                store_id,
                order_id,
                date
            },
            data: driveUpInfo
        })
            .then(response => updateCustomerVehicleDetailsSuccess(dispatch, response))
            .catch(error => updateCustomerVehicleDetailsFail(dispatch, error));
    };
}

const updateCustomerVehicleDetailsSuccess = (dispatch, response) => {
    dispatch(hideLoading());
    if (UtliService.isSuccess(response)) {
        dispatch({
            type: UPDATE_CUSTOMER_VEHICLE_DETAILS_SUCCESS,
            payload: response.data,
        });
    } else {
        UtliService.alertError(response);
    }
};

const updateCustomerVehicleDetailsFail = (dispatch, error) => {
    dispatch(hideLoading());
    UtliService.alertError(error);

    dispatch({
        type: UPDATE_CUSTOMER_VEHICLE_DETAILS_FAIL,
        payload: error
    });
    throw error;

};

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------- ORDER NOT RECEIVED -------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const triggerOrderNotReceived = (store_id, order_id, date) => {

    return async (dispatch, getState) => {
        dispatch(showLoading());
        dispatch({
            type: ORDER_NOT_RECEIVED_COMPLETED
        });

        return axios({
            method: 'PATCH',
            url: `/carryout/orderNotRecv`,
            params: {
                store_id,
                order_id,
                date
            },
        })
            .then(response => triggerOrderNotReceivedSuccess(dispatch, response))
            .catch(error => triggerOrderNotReceivedFail(dispatch, error));
    };
}

const triggerOrderNotReceivedSuccess = (dispatch, response) => {
    dispatch(hideLoading());
    if (UtliService.isSuccess(response)) {
        dispatch({
            type: ORDER_NOT_RECEIVED_COMPLETED_SUCCESS,
            payload: response.data,
        });
    } else {
        UtliService.alertError(response);
    }
};

const triggerOrderNotReceivedFail = (dispatch, error) => {
    dispatch(hideLoading());
    UtliService.alertError(error);

    dispatch({
        type: ORDER_NOT_RECEIVED_COMPLETED_FAIL,
        payload: error
    });
    throw error;

};