import { combineReducers } from 'redux';

import MainReducer from './MainReducer';
import LoadingReducer from './LoadingReducer';
import WebsocketReducer from './WebsocketReducer';

const appReducer = combineReducers({
    main: MainReducer,
    loading: LoadingReducer,
    websocket: WebsocketReducer
});

const rootReducer = (state, action) => {
//   if (action.type === BACKEND_LOGOUT_USER) {
//     state = undefined;
//   }

  return appReducer(state, action);
};

export default rootReducer;