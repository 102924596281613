export const SHOW_LOADING                               = 'LOADING/SHOW';
export const HIDE_LOADING                               = 'LOADING/HIDE';

export const NEXT_STEP                                  = 'NEXT_STEP';
export const PREV_STEP                                  = 'PREV_STEP';
export const GO_TO_STEP                                 = 'GO_TO_STEP';

export const UPDATE_TASK_LOG                            = 'UPDATE_TASK_LOG';
export const UPDATE_MOCK_CARRY_OUT_INFO                 = 'UPDATE_MOCK_CARRY_OUT_INFO';


// -----------------------------------------------------------------------
// ---------ORDER---------------------------------------------------
// -----------------------------------------------------------------------

export const GET_ORDER_STATUS                           = 'get_order_status';
export const GET_ORDER_STATUS_SUCCESS                   = 'get_order_status_success';
export const GET_ORDER_STATUS_FAIL                      = 'get_order_status_fail';

export const GET_CUSTOMER_ARRIVED_STATUS                = 'get_customer_arrived_status';
export const GET_CUSTOMER_ARRIVED_STATUS_SUCCESS        = 'get_customer_arrived_status_success';
export const GET_CUSTOMER_ARRIVED_STATUS_FAIL           = 'get_customer_arrived_status_fail';

export const UPDATE_CUSTOMER_VEHICLE_DETAILS            = 'update_customer_vehicle_details_status';
export const UPDATE_CUSTOMER_VEHICLE_DETAILS_SUCCESS    = 'update_customer_vehicle_details_status_success';
export const UPDATE_CUSTOMER_VEHICLE_DETAILS_FAIL       = 'update_customer_vehicle_details_status_fail';

export const ORDER_NOT_RECEIVED_COMPLETED               = 'order_not_received_completed';
export const ORDER_NOT_RECEIVED_COMPLETED_SUCCESS       = 'order_not_received_completed_success';
export const ORDER_NOT_RECEIVED_COMPLETED_FAIL          = 'order_not_received_completed_fail';

export const CUSTOMER_CLICKED_DRIVEUP                   = 'customer_clicked_driveup';
export const CUSTOMER_CLICKED_DRIVEUP_SUCCESS           = 'customer_clicked_driveup_success';
export const CUSTOMER_CLICKED_DRIVEUP_FAIL              = 'customer_clicked_driveup_fail';

export const CUSTOMER_CLICKED_WALKUP                    = 'customer_clicked_walkup';
export const CUSTOMER_CLICKED_WALKUP_SUCCESS            = 'customer_clicked_walkup_success';
export const CUSTOMER_CLICKED_WALKUP_FAIL               = 'customer_clicked_walkup_fail';

export const GET_CHECK_STORE_CODE                       = 'get_check_store_code';
export const GET_CHECK_STORE_CODE_SUCCESS               = 'get_check_store_code_success';
export const GET_CHECK_STORE_CODE_FAIL                  = 'get_check_store_code_fail';

export const CLAIM_MANAGER_CODE                         = 'claim_manager_code';
export const CLAIM_MANAGER_CODE_SUCCESS                 = 'claim_manager_code_success';
export const CLAIM_MANAGER_CODE_FAIL                    = 'claim_manager_code_fail';

// -----------------------------------------------------------------------
// ---------WEBSOCKET---------------------------------------------------
// -----------------------------------------------------------------------
export const START_WEBSOCKET                            = 'start_websocket';
export const STOP_WEBSOCKET                             = 'stop_websocket';

export const CARRYOUT_ORDER_UPDATED                     = 'carryout_order_updated';

export const CUSTOMER_ORDER_SENT                        = 'customer_order_sent';
export const CUSTOMER_ORDER_FINISH                      = 'customer_order_finish';

export const REFRESH                                    = 'REFRESH';
