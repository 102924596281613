/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Trans, withTranslation } from 'react-i18next';
import {
	getQueryVariable,
	isNowCompareToTargetTime,
	isIOCompareToTargetTime,
	isNowCompareToOutTheDoorDeliverTime,
	currentTime
} from "services";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { DEBUG, REFRESH_MILISECOND } from "../config";

import {
	triggerCustomerArrived,
	onClaimCoupon,
	nextStep,
	prevStep,
	goToStep,
	triggerCustomerWalkup,
	triggerCustomerDriveup,
	updateCustomerVehicleDetails,
	triggerOrderNotReceived,
	updateTaskLog,
	updateCarryOutInfo,
	getOrderStatus
} from "../actions";
import { METHOD_TYPE, STATE } from "constants/variable";
import { FooterComponent } from "./Footer";
var Timer;

class ContentPageBase extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			time: currentTime,
			statusDescription: "",
			storeId: getQueryVariable("storeId"),
			orderId: getQueryVariable("orderId"),
			date: getQueryVariable("date"),
			email: "",
			statusSubTitle: "",
			statusSubDescription: '',
			explaination: '',
			vehicleMake: "",
			vehicleModel: "",
			vehicleColor: "",
			vehicleRegNumber: "",
		};
	}

	componentDidMount() {
		document.addEventListener("touchstart", {});
		this._isMounted = true;
		this._isMounted && this.onLoad();
	}

	componentWillUnmount() {
		document.removeEventListener("touchstart", {});
		this._isMounted = false;
		clearTimeout(Timer);

		clearInterval(this.interval);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.currentCarryoutInfo !== prevProps.currentCarryoutInfo) {
			if (this.props.currentCarryoutInfo.state === STATE.COMPLETE) {
				if (this.props.currentCarryoutInfo.storeActionInfo && this.props.currentCarryoutInfo.storeActionInfo.sendClickDateTime) {
					this.goToThankYouPage();
				}
			}
		}
	}

	onLoad() {
		const { currentCarryoutInfo } = this.props;
		if (currentCarryoutInfo) {

			const { clientInfo, driveUpInfo } = this.props.currentCarryoutInfo;

			this.setState(prevState => ({
				...prevState,
				email: clientInfo.customerEmail,
				vehicleMake: (driveUpInfo && driveUpInfo.vehicleMake) || '',
				vehicleModel: (driveUpInfo && driveUpInfo.vehicleModel) || '',
				vehicleColor: (driveUpInfo && driveUpInfo.vehicleColor) || '',
				vehicleRegNumber: (driveUpInfo && driveUpInfo.vehicleRegNumber) || '',
			}), () => this.checkStatus());
		}
	}

	loadOrderInfo() {
		const { storeId, orderId, date } = this.state;
		this.props.getOrderStatus(storeId, orderId, date);
	}

	startTimer() {
		this.updateDescription();
		this.interval = setInterval(() => {
			this.setState({ time: currentTime });
			this.updateDescription();
		}, REFRESH_MILISECOND);
	}

	checkStatus() {
		const { currentCarryoutInfo } = this.props;
		const { detailInfo, taskLogInfoList, driveUpInfo, storeActionInfo } = currentCarryoutInfo;

		const isArrived = _.find(taskLogInfoList, (o) => o.state === STATE.ARRIVED);

		if (currentCarryoutInfo.state === STATE.COMPLETE && storeActionInfo && storeActionInfo.sendClickDateTime) {
			this.goToThankYouPage();
		} else {

			if (isArrived) {
				this.startTimer();
				// this.goToArrivedPage();
				// return;
			} 

			if (!isArrived && detailInfo.methodType === METHOD_TYPE.CARRY) { //walk up
				this.goToArrivedPage();
				return;
			}

			if (!isArrived && detailInfo.methodType === METHOD_TYPE.DRIVE) { //walk up
				if (driveUpInfo) {
					this.goToArrivedPage();
					return;
				} else {
					this.goToStep(2);
					return;
				}
			}
		}

	}

	goToStep(nextPage) {
		if (this.props.page === 6) {
			clearTimeout(Timer);
			clearInterval(this.interval);
			this.props.goToStep(nextPage);

		} else if (this.props.page !== nextPage) {
			if (nextPage === 4) {
				clearTimeout(Timer);
				clearInterval(this.interval);
			};
			this.props.goToStep(nextPage);
		}
	}

	nextStep() {
		if (this.props.page !== this.props.page + 1) {
			this.props.nextStep();
		}
	}

	goToArrivedPage() {
		this.goToStep(3);
	}

	onTriggerArrived() {
		const { storeId, orderId, date } = this.state;
		this.props
			.triggerCustomerArrived(storeId, orderId, date)
			.then(() => {
				this.loadOrderInfo();
				this.startTimer()
			});
	}

	onTriggerDriveup() {
		const { storeId, orderId, date } = this.state;
		this.props
			.triggerCustomerDriveup(storeId, orderId, date)
			.then(() => this.nextStep());
	}

	onTriggerWalkup() {
		const { storeId, orderId, date } = this.state;
		this.props
			.triggerCustomerWalkup(storeId, orderId, date)
			.then(() => this.goToArrivedPage());
	}

	onTriggerTrackerComplete() {
		const { storeId, orderId, date } = this.state;
		this.props.triggerOrderNotReceived(storeId, orderId, date);
	}

	onBack() {
		this.goToStep(1);
	}

	updateDescription() {

		const { currentCarryoutInfo, t } = this.props;
		const { detailInfo, taskLogInfoList, storeActionInfo, couponInfo } = currentCarryoutInfo;
		const { coSettingInfo } = detailInfo;

		const targetOrderTime = moment(detailInfo.targetDeliveryDateTime.$date);
		const inOvenToReadyBufferMin = coSettingInfo.inOvenToReadyBufferMin;
		const orderDropToReadyBufferMin = coSettingInfo.orderDropToReadyBufferMin;

		const orderInOven = _.find(taskLogInfoList, (o) => o.state === STATE.IN_OVEN);

		if (currentCarryoutInfo.state === STATE.COMPLETE) {
			if (storeActionInfo && storeActionInfo.sendClickDateTime) {
				this.goToThankYouPage();
			} else {

				if (detailInfo.methodType === METHOD_TYPE.CARRY) { // walkup
					this.setState({ statusDescription: t('MSG_PAGE_ARRIVAL.complete_pickup'), explaination: t('MSG_PAGE_ARRIVAL.collect_zero_lane') });
					this.pickup();
					return;
				} else {
					this.setState({ statusDescription: t('MSG_PAGE_ARRIVAL.complete_pickup'), explaination: t('MSG_PAGE_ARRIVAL.send_to_car') });
					this.pickup();
					return;
				}
			}

		} else {
			if (orderInOven) {
				if (isIOCompareToTargetTime(moment(orderInOven.serverDatetime.$date), targetOrderTime, inOvenToReadyBufferMin)) { //if IO + 7 > delivery time
					if (detailInfo.methodType === METHOD_TYPE.CARRY) { // walkup
						this.setState({ statusDescription: t('MSG_PAGE_ARRIVAL.complete_pickup'), explaination: t('MSG_PAGE_ARRIVAL.collect_zero_lane') });
						if (couponInfo && couponInfo.couponClaimDateTime) {
							this.setState({
								statusSubTitle: t('MSG_PAGE_ARRIVAL.sincerely'),
								statusSubDescription: t('MSG_PAGE_ARRIVAL.order_delayed')
							});
							return;
						} else {
							this.redeemVoucherWithoutNotRecevied();
						}
						return;
					} else {
						this.setState({ statusDescription: t('MSG_PAGE_ARRIVAL.complete_pickup'), explaination: t('MSG_PAGE_ARRIVAL.send_to_car') });
						if (couponInfo && couponInfo.couponClaimDateTime) {
							this.setState({
								statusSubTitle: t('MSG_PAGE_ARRIVAL.sincerely'),
								statusSubDescription: t('MSG_PAGE_ARRIVAL.order_delayed')
							});
							return;
						} else {
							this.redeemVoucherWithoutNotRecevied();
						}
						return;
					}
				} else {
					this.setState({ statusDescription: t('MSG_PAGE_ARRIVAL.ready_soon'), explaination: '' });
					return;
				}
			} else {
				if (isNowCompareToTargetTime(targetOrderTime, orderDropToReadyBufferMin)) { //if currentTime + 8 > delivery time
					if (detailInfo.methodType === METHOD_TYPE.CARRY) { // walkup
						this.setState({ statusDescription: t('MSG_PAGE_ARRIVAL.complete_pickup'), explaination: t('MSG_PAGE_ARRIVAL.collect_zero_lane') });

						if (couponInfo && couponInfo.couponClaimDateTime) {
							this.setState({
								statusSubTitle: t('MSG_PAGE_ARRIVAL.sincerely'),
								statusSubDescription: t('MSG_PAGE_ARRIVAL.order_delayed')
							});
							return;
						} else {
							this.redeemVoucherWithoutNotRecevied();
						}
						return;
					} else {
						this.setState({ statusDescription: t('MSG_PAGE_ARRIVAL.complete_pickup'), explaination: t('MSG_PAGE_ARRIVAL.send_to_car') });

						if (couponInfo && couponInfo.couponClaimDateTime) {
							this.setState({
								statusSubTitle: t('MSG_PAGE_ARRIVAL.sincerely'),
								statusSubDescription: t('MSG_PAGE_ARRIVAL.order_delayed')
							});
							return;
						} else {
							this.redeemVoucherWithoutNotRecevied();
						}
						return;
					}
				} else {
					this.setState({ statusDescription: t('MSG_PAGE_ARRIVAL.ready_soon'), explaination: '' });
					return;
				}
			}
		}
	};

	pickup() {
		const { currentCarryoutInfo, t } = this.props;
		const { detailInfo, couponInfo, taskLogInfoList } = currentCarryoutInfo;
		const { coSettingInfo, targetDeliveryDateTime } = detailInfo;

		const isArrived = _.find(taskLogInfoList, (o) => o.state === STATE.ARRIVED);
		const orderCompleted = _.find(taskLogInfoList, (o) => o.state === STATE.COMPLETE);
		const targetOrderTime = moment(targetDeliveryDateTime.$date);

		const completeToVehicleSec = (coSettingInfo.completeToVehicleSec * 1000);
		this.goToArrivedPage();
		if (couponInfo && couponInfo.couponClaimDateTime) {
			this.setState({
				statusSubTitle: t('MSG_PAGE_ARRIVAL.sincerely'),
				statusSubDescription: t('MSG_PAGE_ARRIVAL.order_delayed')
			});
			return;
		}

		if (isNowCompareToOutTheDoorDeliverTime(moment(isArrived.serverDatetime.$date), moment(orderCompleted.serverDatetime.$date),completeToVehicleSec)) {
			clearTimeout(Timer);
			if (currentTime.isSame(targetOrderTime, 'day')) {
				this.goToStep(4);
			}
		} else {
			clearTimeout(Timer);
			if (currentTime.isSame(targetOrderTime, 'day')) {
				Timer = setTimeout(() => this.goToStep(4), completeToVehicleSec);
			}
		}
	}

	goToThankYouPage() {
		clearInterval(this.interval);
		clearTimeout(Timer);
		this.goToStep(6);
		return;
	}

	onSubmitEmail({ email }) {
		this.setState({ email });
		const { storeId, orderId, date } = this.state;
		clearTimeout(Timer);

		clearInterval(this.interval);
		this.props.onClaimCoupon(storeId, orderId, date, email)
			.then(() => {
				this.doneRedeemVoucher();
			});
	}

	redeemVoucherWithoutNotRecevied() {
		clearTimeout(Timer);
		clearInterval(this.interval);
		const { email, storeId, orderId, date } = this.state;

		if (email) {
			this.props.onClaimCoupon(storeId, orderId, date, email);
		}
		this.goToStep(5);
	}

	redeemVoucher() {
		this.onTriggerTrackerComplete();
		clearTimeout(Timer);
		clearInterval(this.interval);

		const { email, storeId, orderId, date } = this.state;

		if (email) {
			this.props.onClaimCoupon(storeId, orderId, date, email);
		}
		this.goToStep(5);
	}

	onSubmitVehicleDetail({
		vehicleMake,
		vehicleModel,
		vehicleColor,
		vehicleRegNumber,
	}) {
		const { storeId, orderId, date } = this.state;
		this.props
			.updateCustomerVehicleDetails({ vehicleMake, vehicleModel, vehicleColor, vehicleRegNumber }, storeId, orderId, date)
			.then(() => this.goToArrivedPage());
	}

	renderWherePizzaButton() {
		return (
			<>
				<div className="container">
					<div className="has-text-centered">
						<button
							className="button is-large is-danger is-fullwidth is-inline-block has-text-weight-semibold"
							style={{ height: "6rem" }}
							onClick={() => this.redeemVoucher()}
						>
							<p className="title is-5 has-text-white is-size-6-mobile is-uppercase" style={{ whiteSpace: "pre-wrap" }}>
								<Trans>MSG_PAGE_PIZZA.not_received_order</Trans>
							</p>
							<p className="subtitle is-6 has-text-white is-size-7-mobile" style={{ whiteSpace: "pre-wrap" }}>
								<Trans>MSG_PAGE_PIZZA.click_button</Trans>
							</p>
						</button>
					</div>
				</div>
			</>
		);
	}

	renderFreePizza({
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		handleSubmit,
	}) {
		const { t } = this.props;
		return (
			<div className="has-text-centered">
				<form onSubmit={handleSubmit}>
					<div className="field is-horizontal is-grouped-centered">
						<div className="field-label is-normal">
							<label className="label has-text-left">
								<span className="has-text-danger">*</span><Trans>EMAIL_FORM.label_email</Trans>
							</label>
						</div>
						<div className="field-body">
							<div className="field">
								<p className="control">
									<input
										className="input"
										type="email"
										placeholder={t('EMAIL_FORM.placeholder_email')}
										value={values.email}
										onChange={handleChange}
										name="email"
										onBlur={handleBlur}
									/>
								</p>
								<p className="help is-danger">
									{errors.email &&
										touched.email &&
										errors.email}
								</p>
							</div>
						</div>
					</div>

					<div
						className="buttons are-medium is-centered"
						style={{ padding: "1rem" }}
					>
						<button className="button is-danger" type="submit">
							<Trans>BUTTON.finish</Trans>
						</button>
					</div>
				</form>
			</div>
		);
	}

	renderVehicleDetail({
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		handleSubmit,
	}) {
		const { t } = this.props;
		return (
			<>
				<p className="title is-5 is-size-6-mobile has-text-centered">
					<Trans>MSG_PAGE_VEHICLE.share_detail</Trans>
				</p>
				<div className="">
					<form onSubmit={handleSubmit}>
						<div className="field is-horizontal is-grouped-centered">
							<div className="field-label is-normal">
								<label className="label"><span className="has-text-danger">*</span> <Trans>VEHICLE_FORM.label_reg_num</Trans></label>

							</div>
							<div className="field-body">
								<div className="field">
									<p className="control">
										<input
											className="input"
											type="text"
											placeholder={t("VEHICLE_FORM.placeholder_reg_num")}
											value={values.vehicleRegNumber}
											onChange={handleChange}
											name="vehicleRegNumber"
											onBlur={handleBlur}
										/>
									</p>
									<p className="help is-danger">
										{errors.vehicleRegNumber &&
											touched.vehicleRegNumber &&
											errors.vehicleRegNumber}
									</p>
								</div>
							</div>
						</div>

						<div className="field is-horizontal is-grouped-centered">
							<div className="field-label is-normal">
								<label className="label">
									<Trans>VEHICLE_FORM.label_make</Trans>
								</label>
							</div>
							<div className="field-body">
								<div className="field">
									<p className="control">
										<input
											className="input"
											type="text"
											placeholder={t("VEHICLE_FORM.placeholder_make")}
											value={values.vehicleMake}
											onChange={handleChange}
											name="vehicleMake"
											onBlur={handleBlur}
										/>
									</p>
									<p className="help is-danger">
										{errors.vehicleMake &&
											touched.vehicleMake &&
											errors.vehicleMake}
									</p>
								</div>
							</div>
						</div>

						<div className="field is-horizontal is-grouped-centered">
							<div className="field-label is-normal">
								<label className="label">
									<Trans>VEHICLE_FORM.label_model</Trans>
								</label>
							</div>
							<div className="field-body">
								<div className="field">
									<p className="control">
										<input
											className="input"
											type="text"
											placeholder={t("VEHICLE_FORM.placeholder_model")}
											value={values.vehicleModel}
											onChange={handleChange}
											name="vehicleModel"
											onBlur={handleBlur}
										/>
									</p>
									<p className="help is-danger">
										{errors.vehicleModel &&
											touched.vehicleModel &&
											errors.vehicleModel}
									</p>
								</div>
							</div>
						</div>

						<div className="field is-horizontal is-grouped-centered">
							<div className="field-label is-normal">
								<label className="label">
									<Trans>VEHICLE_FORM.label_color</Trans>
								</label>
							</div>
							<div className="field-body">
								<div className="field">
									<p className="control">
										<input
											className="input"
											type="text"
											placeholder={t("VEHICLE_FORM.placeholder_color")}
											value={values.vehicleColor}
											onChange={handleChange}
											name="vehicleColor"
											onBlur={handleBlur}
										/>
									</p>
									<p className="help is-danger">
										{errors.vehicleColor &&
											touched.vehicleColor &&
											errors.vehicleColor}
									</p>
								</div>
							</div>
						</div>

						<div className="buttons are-medium is-centered" style={{ padding: "1rem" }}>
							<button className="button is-danger" onClick={() => this.onBack()}>
								<Trans>BUTTON.back</Trans>
							</button>
							<button className="button is-danger" type="submit">
								<Trans>BUTTON.next</Trans>
							</button>
						</div>
						<p className="title is-6 has-text-grey-dark has-text-centered">
							<Trans>MSG_PAGE_VEHICLE.harzard_light</Trans>
						</p>
					</form>
				</div>
			</>
		);
	}

	renderThankYouMsg() {
		return (
			<>
				<h3 className="title is-3 has-text-centered has-text-grey-darker">
					<Trans>MSG_PAGE_END.thank_you</Trans>
				</h3>
				<h3 className="title is-3 has-text-centered has-text-grey-darker">
					<Trans>MSG_PAGE_END.enjoy</Trans>
				</h3>
			</>
		);
	}

	renderChooseMethod() {
		if (this.props.currentCarryoutInfo === null) return <></>;

		const { detailInfo } = this.props.currentCarryoutInfo;

		return (
			<>
				<div className="container is-fluid">
					<div className="has-text-centered">
						<p className="title is-5 is-size-6-mobile">
							<Trans>MSG_PAGE_METHOD.take_away_method</Trans>
						</p>

						<div className="columns is-mobile">
							<div className="column is-half">
								<button
									className={`button is-danger is-fullwidth is-inline-block`}
									style={{ height: "6rem" }}
									onClick={() => this.onTriggerWalkup()}
								>
									<p className="title is-4 has-text-white has-text-weight-semibold is-size-6-mobile is-uppercase" style={{ whiteSpace: "pre-wrap" }}>
										<Trans>BUTTON.potong_q</Trans>
									</p>
								</button>
								<p
									className="subtitle is-6 has-text-grey-darker has-text-weight-medium"
									style={{ paddingTop: "15px" }}
								>
									<Trans>MSG_PAGE_METHOD.proceed_to_store</Trans>
								</p>
							</div>
							<div className="column is-half">
								<button
									className={`button is-danger is-fullwidth is-inline-block ${!(detailInfo.coSettingInfo && detailInfo.coSettingInfo.allowDriveUp) ? "Disabled" : ""}`}
									style={{ height: "6rem" }}
									onClick={() => this.onTriggerDriveup()}
									disabled={!(detailInfo.coSettingInfo && detailInfo.coSettingInfo.allowDriveUp)}
								>
									<p className="title is-4 has-text-white has-text-weight-semibold is-size-6-mobile is-uppercase" style={{ whiteSpace: "pre-wrap" }}>
										<Trans>BUTTON.walk_in</Trans>

									</p>
								</button>
								<p
									className="subtitle is-6 has-text-grey-darker has-text-weight-medium"
									style={{ paddingTop: "15px" }}
								>
									<Trans>MSG_PAGE_METHOD.wait_in_car</Trans>
								</p>
							</div>
						</div>
						<p
							className="subtitle is-5 has-text-grey-darker has-text-weight-bold"
							style={{ paddingTop: "15px" }}
						>
							<Trans>MSG_PAGE_METHOD.cash_payment</Trans>
						</p>
					</div>
				</div>
			</>
		);
	}

	renderCustomerArrvied() {
		if (this.props.currentCarryoutInfo === null) return <></>;

		const { detailInfo, taskLogInfoList } = this.props.currentCarryoutInfo;
		const isArrived = _.find(taskLogInfoList, (o) => o.state === STATE.ARRIVED);
		
		return (
			<>
				<div className="has-text-centered">
					{
						isArrived?
							<div>
								<div className="box">
									<p className="title is-4">
										{this.state.statusDescription}
									</p>
									<h1 className="subtitle is-6">
										{this.state.explaination}
									</h1>
								</div>
								<h1 className="title is-5">
									{this.state.statusSubTitle}
								</h1>
								<h2 className="subtitle is-6 is-italic has-text-grey-dark has-text-weight-bold">
									{this.state.statusSubDescription}
								</h2>
							</div>
							:
							<button
								className="button is-large is-danger is-fullwidth is-inline-block has-text-weight-semibold"
								style={{ height: "6rem" }}
								onClick={() => this.onTriggerArrived()}
							>
								<p className="title is-3 has-text-white is-size-5-mobile is-uppercase" style={{ whiteSpace: "pre-wrap" }}>
									<Trans>MSG_PAGE_ARRIVAL.im_here</Trans>
								</p>
								<p className="subtitle is-6 has-text-white is-size-7-mobile" style={{ whiteSpace: "pre-wrap" }}>
									<Trans>MSG_PAGE_ARRIVAL.click_this</Trans>
								</p>
							</button>
					}
				</div>

				<div className="container has-text-centered" style={{ marginTop: "10px" }}>
					{detailInfo.methodType === METHOD_TYPE.DRIVE && (
						<p className="title is-6 has-text-grey-darker ">
							<Trans>MSG_PAGE_ARRIVAL.harzard_light</Trans>
						</p>
					)}
				</div>
				{
					isArrived ?
						null
						:
						<div
							className="buttons are-medium is-centered"
							style={{ padding: "3rem" }}
						>
							<button
								className="button is-primary"
								onClick={() => this.navigateBackToMethod()}
							>
								Back
							</button>

						</div>
				}


			</>
		);
	}

	navigateBackToMethod() {
		const { currentCarryoutInfo } = this.props;
		const { detailInfo, driveUpInfo } = currentCarryoutInfo;

		if (detailInfo.methodType === METHOD_TYPE.CARRY) {
			this.goToStep(1);
		} else {
			this.setState(prevState => ({
				vehicleMake: driveUpInfo.vehicleMake,
				vehicleModel: driveUpInfo.vehicleModel,
				vehicleColor: driveUpInfo.vehicleColor,
				vehicleRegNumber: driveUpInfo.vehicleRegNumber,
			}), () => this.goToStep(2))
		}
	}

	renderVehicleDetailFormik() {
		const { t } = this.props;
		return (
			<Formik
				initialValues={this.state}
				validationSchema={Yup.object().shape({
					vehicleMake: Yup.string()
						.max(50, t("VEHICLE_FORM.required_make_max")),
					vehicleModel: Yup.string()
						.max(50, t("VEHICLE_FORM.required_model_max")),
					vehicleColor: Yup.string()
						.max(50, t("VEHICLE_FORM.required_color_max")),
					vehicleRegNumber: Yup.string()
						.required(t("VEHICLE_FORM.required_reg_num"))
						.trim()
						.uppercase()
						.max(20, t("VEHICLE_FORM.required_reg_num_max")),
				})}
				onSubmit={(values) => this.onSubmitVehicleDetail(values)}
			>
				{(props) => this.renderVehicleDetail(props)}
			</Formik>
		);
	}

	renderEmailMsgFormik() {
		const { email } = this.state;
		const { t } = this.props;

		return (
			<>
				<div className="has-text-centered">
					<p className="title is-5">
						<Trans>MSG_PAGE_EMAIL.free_pizza</Trans>
					</p>
					<p className="subtitle is-6 is-italic has-text-grey-dark">
						<Trans>MSG_PAGE_EMAIL.terms</Trans>
					</p>
					{!email ? (
						(
							<Formik
								initialValues={this.state}
								validationSchema={Yup.object().shape({
									email: Yup.string().email().required(t("EMAIL_FORM.required_email")),
								})}
								onSubmit={(values) => this.onSubmitEmail(values)}
							>
								{(props) => this.renderFreePizza(props)}
							</Formik>
						)

					) :
						<div
							className="buttons are-medium is-centered"
							style={{ padding: "1rem" }}
						>
							<button className="button is-danger" type="button" onClick={() => this.doneRedeemVoucher()}>
								<Trans>BUTTON.finish</Trans>
							</button>
						</div>
					}
				</div>

			</>
		);
	}

	doneRedeemVoucher() {
		const { currentCarryoutInfo, t } = this.props;
		const { couponInfo } = currentCarryoutInfo;

		if (currentCarryoutInfo.state === STATE.COMPLETE) {
			if (currentCarryoutInfo.storeActionInfo && currentCarryoutInfo.storeActionInfo.sendClickDateTime) {
				clearTimeout(Timer);
				clearInterval(this.interval);
				this.goToStep(6);
				return;
			} else {
				if (couponInfo && couponInfo.couponClaimDateTime) {
					this.setState({
						statusSubTitle: t('MSG_PAGE_ARRIVAL.sincerely'),
						statusSubDescription: t('MSG_PAGE_ARRIVAL.order_delayed')
					});
				}
				this.goToArrivedPage();
				this.startTimer();
			}
		} else {
			this.goToArrivedPage();
			this.startTimer();
		}
	}

	renderTiming() {
		const { currentCarryoutInfo } = this.props;
		const { detailInfo, taskLogInfoList } = currentCarryoutInfo;

		const orderTime = moment(detailInfo.orderDateTime.$date).clone();
		const targetOrderTime = moment(detailInfo.targetDeliveryDateTime.$date).clone();

		const orderPlace = _.find(taskLogInfoList, (o) => o.state === "OP");
		const orderDropped = _.find(taskLogInfoList, (o) => o.state === "PC");
		const orderInOven = _.find(taskLogInfoList, (o) => o.state === "IO");
		const orderCompleted = _.find(taskLogInfoList, (o) => o.state === "CP");

		return (
			<div className="container has-text-grey-light is-size-7">
				<div style={{ height: "30px" }}></div>
				{currentCarryoutInfo.clientInfo.customerName && (
					<p> {currentCarryoutInfo.clientInfo.customerName}</p>
				)}
				<p>Timer: {this.state.time && this.state.time.format("DD/MM/YY hh:mm:a ")}</p>
				<p>Current Time: {currentTime.format("DD/MM/YY hh:mm:a")}</p>
				<p>Order Time: {orderTime.format("DD/MM/YY hh:mm:a ")}</p>
				<p>
					OP-Order Place:{" "}
					{orderPlace &&
						moment(orderPlace.serverDatetime.$date).format("DD/MM/YY hh:mm:a")}
				</p>
				<p>
					PC-Order Dropped:{" "}
					{orderDropped &&
						moment(orderDropped.serverDatetime.$date).format("DD/MM/YY hh:mm:a")}
				</p>
				<p>
					IO-Order In Oven:{" "}
					{orderInOven &&
						moment(orderInOven.serverDatetime.$date).format("DD/MM/YY hh:mm:a")}
				</p>
				<p>
					CP-Order Completed:{" "}
					{orderCompleted &&
						moment(orderCompleted.serverDatetime.$date).format("DD/MM/YY hh:mm:a")}
				</p>
				<p>Target Time: {targetOrderTime.format("DD/MM/YY hh:mm:a")}</p>
				<p>State: {currentCarryoutInfo.state}</p>
			</div>
		);
	}

	render() {
		if (!this.props.currentCarryoutInfo) return null;
		const { page, currentCarryoutInfo, t } = this.props;
		const { detailInfo } = currentCarryoutInfo;
		return (
			<>
				<div className="panel">
					{
						page === 6 ?
							<><FooterComponent /></>
							:
							<>
								<p className="panel-heading is-uppercase">
									{page === 1 ? t('HEADER.take_away') : null}
									{page === 2 ? t('HEADER.pandu_ambil_vehicle') : null}
									{page >= 3 && detailInfo.methodType === METHOD_TYPE.CARRY ? (
										<Trans>HEADER.potong_q</Trans>
									) : null}
									{page >= 3 && detailInfo.methodType === METHOD_TYPE.DRIVE ? (
										<Trans>HEADER.pandu_ambil</Trans>
									) : null}

								</p>
								<FooterComponent />
							</>
					}

					<section className="hero">
						<div className="hero-body">
							{page === 1 ? this.renderChooseMethod() : null}
							{page === 2 ? this.renderVehicleDetailFormik() : null}
							{page === 3 ? this.renderCustomerArrvied() : null}
							{page === 4 ? this.renderWherePizzaButton() : null}
							{page === 5 ? this.renderEmailMsgFormik() : null}
							{page === 6 ? this.renderThankYouMsg() : null}
							{DEBUG && this.renderTiming()}
						</div>
					</section>
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ main }) => {
	const { currentCarryoutInfo, page } = main;
	return { currentCarryoutInfo, page };
};

const Content = compose(
	withRouter,
	connect(mapStateToProps, {
		triggerCustomerArrived,
		onClaimCoupon,
		nextStep,
		prevStep,
		goToStep,
		triggerCustomerWalkup,
		triggerCustomerDriveup,
		updateCustomerVehicleDetails,
		triggerOrderNotReceived,
		updateTaskLog,
		updateCarryOutInfo,
		getOrderStatus
	}),
	withTranslation(),
)(ContentPageBase);

export default Content;