import {
	GET_ORDER_STATUS,
	GET_ORDER_STATUS_SUCCESS,
	GET_ORDER_STATUS_FAIL,
	GET_CUSTOMER_ARRIVED_STATUS,
	GET_CUSTOMER_ARRIVED_STATUS_SUCCESS,
	GET_CUSTOMER_ARRIVED_STATUS_FAIL,
	GET_CHECK_STORE_CODE,
	GET_CHECK_STORE_CODE_SUCCESS,
	GET_CHECK_STORE_CODE_FAIL,
	CLAIM_MANAGER_CODE_FAIL,
	CLAIM_MANAGER_CODE_SUCCESS,
	CLAIM_MANAGER_CODE,
	ORDER_NOT_RECEIVED_COMPLETED_SUCCESS,
	NEXT_STEP,
	PREV_STEP,
	GO_TO_STEP,
	UPDATE_CUSTOMER_VEHICLE_DETAILS,
	UPDATE_CUSTOMER_VEHICLE_DETAILS_SUCCESS,
	UPDATE_CUSTOMER_VEHICLE_DETAILS_FAIL,
	CUSTOMER_CLICKED_DRIVEUP,
	CUSTOMER_CLICKED_DRIVEUP_SUCCESS,
	CUSTOMER_CLICKED_DRIVEUP_FAIL,
	CUSTOMER_CLICKED_WALKUP,
	CUSTOMER_CLICKED_WALKUP_SUCCESS,
	CUSTOMER_CLICKED_WALKUP_FAIL,
	REFRESH,
	CUSTOMER_ORDER_SENT,
	CUSTOMER_ORDER_FINISH,
	UPDATE_TASK_LOG,
	UPDATE_MOCK_CARRY_OUT_INFO,
	CARRYOUT_ORDER_UPDATED
} from '../actions/types';
import { DEBUG } from 'config';

const INITIAL_STATE = {
	page: 1,
	error: '',
	loading: false,
	currentCarryoutInfo: null
};

export default (state = INITIAL_STATE, action) => {
	let { currentCarryoutInfo } = state;
	switch (action.type) {

		case GO_TO_STEP:
			return {
				...state,
				page: action.payload
			};

		case NEXT_STEP:
			return {
				...state,
				page: state.page + 1
			};

		case PREV_STEP:
			return {
				...state,
				page: state.page - 1
			};

		case GET_ORDER_STATUS:
			return {
				...state,
			};

		case GET_ORDER_STATUS_SUCCESS:
			if(DEBUG) console.log(action.payload.carryOutInfo);

			return {
				...state,
				loading: false,
				currentCarryoutInfo: action.payload.carryOutInfo,
			};

		case GET_ORDER_STATUS_FAIL:
			return {
				...state,
			};

		case GET_CUSTOMER_ARRIVED_STATUS:
			return {
				...state,
			};

		case GET_CUSTOMER_ARRIVED_STATUS_SUCCESS:
			return {
				...state,
				loading: false,
				currentCarryoutInfo: action.payload.carryOutInfo ? action.payload.carryOutInfo : state.currentCarryoutInfo,
			};

		case GET_CUSTOMER_ARRIVED_STATUS_FAIL:
			return {
				...state,
			};

		case CUSTOMER_CLICKED_WALKUP:
			return {
				...state,
			};

		case CUSTOMER_CLICKED_WALKUP_SUCCESS:
			return {
				...state,
				loading: false,
				currentCarryoutInfo: action.payload.carryOutInfo ? action.payload.carryOutInfo : state.currentCarryoutInfo,
			};

		case CUSTOMER_CLICKED_WALKUP_FAIL:
			return {
				...state,
			};

		case CUSTOMER_CLICKED_DRIVEUP:
			return {
				...state,
			};

		case CUSTOMER_CLICKED_DRIVEUP_SUCCESS:
			return {
				...state,
				loading: false,
				currentCarryoutInfo: action.payload.carryOutInfo ? action.payload.carryOutInfo : state.currentCarryoutInfo,
			};

		case CUSTOMER_CLICKED_DRIVEUP_FAIL:
			return {
				...state,
			};

		case UPDATE_CUSTOMER_VEHICLE_DETAILS:
			return {
				...state,
			};

		case UPDATE_CUSTOMER_VEHICLE_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				currentCarryoutInfo: action.payload.carryOutInfo ? action.payload.carryOutInfo : state.currentCarryoutInfo,
			};

		case UPDATE_CUSTOMER_VEHICLE_DETAILS_FAIL:
			return {
				...state,
			};

		case GET_CHECK_STORE_CODE:
			return {
				...state,
			};

		case GET_CHECK_STORE_CODE_SUCCESS:
			return {
				...state,
				loading: false,
				currentCarryoutInfo: action.payload.carryOutInfo ? action.payload.carryOutInfo : state.currentCarryoutInfo,
			};

		case GET_CHECK_STORE_CODE_FAIL:
			return {
				...state,
			};

		case CLAIM_MANAGER_CODE:
			return {
				...state,
			};

		case CLAIM_MANAGER_CODE_SUCCESS:
			return {
				...state,
				currentCarryoutInfo: action.payload.carryOutInfo ? action.payload.carryOutInfo : state.currentCarryoutInfo,
			};

		case CLAIM_MANAGER_CODE_FAIL:
			return {
				...state,
			};

		case ORDER_NOT_RECEIVED_COMPLETED_SUCCESS:
			return {
				...state,
				currentCarryoutInfo: action.payload.carryOutInfo ? action.payload.carryOutInfo : state.currentCarryoutInfo,
			};

		case CUSTOMER_ORDER_SENT:
			return {
				...state,
				currentCarryoutInfo: action.payload,
			};

		case CUSTOMER_ORDER_FINISH:
			return {
				...state,
				currentCarryoutInfo: action.payload,
			};

		case CARRYOUT_ORDER_UPDATED:
			return {
				...state,
				currentCarryoutInfo: action.payload,
			};
			
		case REFRESH:
			return {
				...state,
				currentCarryoutInfo: action.payload,
			};

		case UPDATE_TASK_LOG:
			return {
				...state, 
				currentCarryoutInfo: {
					...currentCarryoutInfo,
					taskLogInfoList: [...currentCarryoutInfo.taskLogInfoList, action.payload]
				}
			};

		case UPDATE_MOCK_CARRY_OUT_INFO:
			return{
				...state, 
				currentCarryoutInfo: {
					...currentCarryoutInfo,
					...action.payload
				}
			};

		default:
			return state;
	}
};
