import React, { Component } from "react";

import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as ROUTES from "../constants/routes";

import { WebsocketServices } from "services/websocket-service";
import Content from "./Content";
import { getQueryVariable } from "services";
// import Message from "./Message";

import { getOrderStatus, nextStep } from "../actions";

class HomePageBase extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      storeId: getQueryVariable("storeId"),
      orderId: getQueryVariable("orderId"),
      date: getQueryVariable("date")
    };
  }

  componentDidMount() {
		document.addEventListener("touchstart", {});

    this._isMounted = true;
    this.onLoad();
  }

  componentWillUnmount() {
		document.removeEventListener("touchstart", {});

    this._isMounted = false;
  }

  onLoad() {
    const { storeId, orderId, date } = this.state;

    if (!storeId || !orderId || !date) {
      this.props.history.push(ROUTES.NOTFOUND);
    }

    this.props.getOrderStatus(storeId, orderId, date).then(() => {
      const { currentCarryoutInfo } = this.props;

      if (currentCarryoutInfo &&
        currentCarryoutInfo.clientActionInfo &&
        currentCarryoutInfo.clientActionInfo.isHereEventDateTime
      ) {
        // this.props.nextStep();
      }
    });
  }

  render() {
    const { storeId, orderId, date } = this.state;
    const { currentCarryoutInfo } = this.props;


    return (
      <>
        {storeId && orderId && date ? <WebsocketServices /> : null}
        {currentCarryoutInfo && <Content/>}
      </>
    );
  }
}

const mapStateToProps = ({ main }) => {
  const { currentCarryoutInfo } = main;
  return { currentCarryoutInfo };
};

const Home = compose(
  withRouter,
  connect(mapStateToProps, {
    getOrderStatus,
    nextStep
  })
)(HomePageBase);

export default Home;
