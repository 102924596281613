import {
    REFRESH,
    CUSTOMER_ORDER_SENT,
    CUSTOMER_ORDER_FINISH,
    CARRYOUT_ORDER_UPDATED
} from './types';

import {DEBUG} from '../config';

export const onWebsocketReceived = (data) => {
    if(DEBUG) console.log(data);
    return (dispatch, getState) => {
        switch(data.msgType){

        case 'storeClickSend':
            if(!data.coTaskInfo) return;
            return dispatch({type: CUSTOMER_ORDER_SENT, payload: data.coTaskInfo})

        case 'storeClickFinish':
            if(!data.coTaskInfo) return;
            return dispatch({type: CUSTOMER_ORDER_FINISH, payload: data.coTaskInfo})

        case 'carryOutOrderUpdated':
            if(!data.coTaskInfo) return;
            return dispatch({type: CARRYOUT_ORDER_UPDATED, payload: data.coTaskInfo})

        case "heartBeatReq":
            let carryoutInfo = getState().main.currentCarryoutInfo;
            return dispatch({type: REFRESH, payload: carryoutInfo})

            default:
                return null
        };
    }
};