import React, {Component} from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { WEBSOCKET_BASE_URL } from '../config';
import { getQueryVariable } from "services";

import { 
    onWebsocketReceived,
} from '../actions';

import { 
    DEBUG,
} from '../config';

const connection = new WebSocket(`${WEBSOCKET_BASE_URL}`,[
    `${getQueryVariable('date')}-${getQueryVariable('storeId')}-${getQueryVariable('orderId')}`,
]);
 
class WebsocketServicesBase extends Component{
    componentDidMount() {
        this.startWebsocket();
    }

    componentDidUpdate(prevProps){
        if (this.props.timeout !== prevProps.timeout) {
            this.props.timeout ? connection.close() : this.startWebsocket();
        }
    }

    connect(){
        this.startWebsocket();
    }

    startWebsocket(timeout){

        connection.onopen = () => {
            if(DEBUG) console.log('ws open');
        };

        connection.onclose = () => {
            if(DEBUG) console.log('ws close');
            setTimeout(function() {
                connect();
            }, 1000);
        };

        connection.onerror = err => {
            console.error( "Socket encountered error: ", err.message, "Closing socket" );
            connection.close();
        };

        connection.onmessage = evt => { 
            const data = JSON.parse(evt.data);
            console.log(data.msgType);
            // if(data.msgType !== "heartBeatReq"){
                this.props.onWebsocketReceived(data);
            // } 
        };
    }

    render() {
        return <></>;
    }
}

const mapStateToProps = ({websocket}) => {
    const {
      timeout
    } = websocket;
  
    return {
        timeout
    };
  };

const WebsocketServices = compose(
    connect(mapStateToProps,{ 
        onWebsocketReceived,
      }),
)(WebsocketServicesBase);

export { WebsocketServices }


