import React, { Component } from "react";
import { FooterComponent } from "./Footer";

class NotFoundPage extends Component {
  render() {
    return (

      <section className="hero is-fullheight">
        <div className="hero-body">
          <div className="container is-fluid">
            <h1 className="title is-1 has-text-centered">404</h1>
            <h3 className="title is-3 has-text-centered">Page not Found</h3>

          </div>
        </div>
        <div className="hero-foot">
          <div className="columns">
            <div className="column">
              <FooterComponent />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default NotFoundPage;
