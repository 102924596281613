import Swal from 'sweetalert2/src/sweetalert2.js';
import { SUCC_CODE, ERROR_CODE } from 'constants/variable';
import i18n from 'i18next';

export const isSuccess = (response) => {
    return response.data.errorCode === SUCC_CODE;
}

export const prompt = (title, content, callback) => {
    Swal.fire(title, content, 'info').then(callback);
}

export const alertError = (response, callBackFunc) => {
    if (response.status === 200) {
        if (response.data.errorCode === SUCC_CODE) {
            return;
        } else if (response.data.errorCode === ERROR_CODE.ERROR_CARRY_OUT_ORDER_NOT_FOUND) {
            Swal.fire(i18n.t('ERROR.HEADER_4'), i18n.t('ERROR.CARRY_OUT_ORDER_NOT_FOUND'), 'error').then();
        } else if (response.data.errorCode === ERROR_CODE.ERROR_CARRY_OUT_ORDER_EXIST) {
            Swal.fire(i18n.t('ERROR.HEADER_1'), i18n.t('ERROR.CARRY_OUT_ORDER_EXIST'), 'error').then();
        } else if (response.data.errorCode === ERROR_CODE.ERROR_CLIENT_ALREADY_CLICKED) {
            Swal.fire(i18n.t('ERROR.HEADER_3'), i18n.t('ERROR.CLIENT_ALREADY_CLICKED'), 'info').then();
        } else if (response.data.errorCode === ERROR_CODE.ERROR_STORE_DO_NOT_HAVE_CODE) {
            Swal.fire(i18n.t('ERROR.HEADER_4'), i18n.t('ERROR.STORE_DO_NOT_HAVE_CODE'), 'error').then();
        } else if (response.data.errorCode === ERROR_CODE.ERROR_INVALID_STORE_CODE) {
            Swal.fire(i18n.t('ERROR.HEADER_1'), i18n.t('ERROR.INVALID_STORE_CODE'), 'error').then();
        } else if (response.data.errorCode === ERROR_CODE.ERROR_STORE_CODE_MISMATCHED) {
            Swal.fire(i18n.t('ERROR.HEADER_4'), i18n.t('ERROR.STORE_CODE_MISMATCHED'), 'error').then();
        } else if (response.data.errorCode === ERROR_CODE.ERROR_COUPON_CLAIMED) {
            // Swal.fire(i18n.t('ERROR.HEADER_4'), i18n.t('ERROR.COUPON_CLAIMED'), 'info').then();
        } else if (response.data.errorCode === ERROR_CODE.ERROR_REQUEST_COUPON_FAILED) {
            Swal.fire(i18n.t('ERROR.HEADER_2'), i18n.t('ERROR.COUPON_CLAIMED_FAILED'), 'error').then();
        }
    } else {
        Swal.fire(i18n.t('ERROR.HEADER_2'), i18n.t('ERROR.DEFAULT'), 'error');
    }
}