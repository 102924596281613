import React from "react";
import { VERSION } from "../config";

const FooterComponent = () => {
  return (
      <p className="has-text-centered has-text-grey-light is-size-7 is-overlay" style={{ paddingTop: "15px"}}>
          <small>v{VERSION}</small>
      </p>
  );
};

export { FooterComponent };
