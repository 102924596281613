import React, { Component } from "react";
import axios from "axios";
import { compose } from "recompose";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import * as ROUTES from "./constants/routes";
import { BASE_URL } from "./config";

import Loading from "./components/Loading";
import Home from "pages/Home";
import NotFoundPage from "pages/NotFound";

import 'sweetalert2/src/sweetalert2.scss'

axios.defaults.baseURL = BASE_URL;

class AppBase extends Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <Loading />
        <Switch>
          <Route exact path={`${ROUTES.HOME}`} component={Home} />
          <Route path={ROUTES.NOTFOUND} component={NotFoundPage} />
          <Redirect to={ROUTES.NOTFOUND} />
          {/* <Route component={NotFoundPage} /> */}
        </Switch>
      </>
    );
  }
}

const App = compose(withRouter)(AppBase);

export default App;
